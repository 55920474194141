import Image from "next/legacy/image";
import Link from "next/link";

import LinkedInLogo from "../public/webstatic/svg/LinkedIn.svg";
import TwitterLogo from "../public/webstatic/svg/Twitter.svg";
import { classNames } from "../utils/classNames";
import { FrecWebPath } from "../utils/paths";

const SOCIAL_SIZE = 24;
const currentYear = new Date().getFullYear();

export const Footer = ({ className }: { className?: string }) => {
  return (
    <footer
      className={classNames(
        "text-frecDarkBeige text-center bg-frecBlack grid grid-cols-[auto_minmax(0,_86rem)_auto]",
        className
      )}
    >
      <div className="">{/* empty */}</div>
      <div className="relative flex flex-col-reverse px-6 py-8 lg:px-16 lg:mx-12 dotted-v lg:border-x pb-9">
        <div className="flex flex-col items-center w-full">
          <div className="flex flex-row gap-4 mb-6 font-normal">
            <Link
              href={FrecWebPath.about}
              className="hover:underline hover:text-frecWhite"
            >
              About
            </Link>
            <Link
              href="https://help.frec.com/"
              className="hover:underline hover:text-frecWhite"
            >
              Help center
            </Link>
            <Link
              href="mailto:help@frec.com"
              className="hover:underline hover:text-frecWhite"
            >
              Contact
            </Link>
            <Link
              href={FrecWebPath.disclosures}
              className="hover:underline hover:text-frecWhite"
            >
              Disclosures
            </Link>
            <Link
              href={FrecWebPath.rss}
              className="sr-only hover:underline hover:text-frecWhite"
              type="application/rss+xml"
              rel="alternate"
            >
              RSS
            </Link>
          </div>
          <div className="flex flex-row items-center gap-4 mb-6">
            <Link
              href="https://twitter.com/frecfinance"
              target="_blank"
              rel="noreferrer"
              title="Frec on Twitter"
            >
              <TwitterLogo
                alt="Twitter"
                className="fill-frecDarkBeige hover:fill-frecWhite"
                width={SOCIAL_SIZE}
                height={SOCIAL_SIZE}
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/frecfinance/"
              target="_blank"
              rel="noreferrer"
              title="Frec on LinkedIn"
            >
              <LinkedInLogo
                alt="LinkedIn"
                className="fill-frecDarkBeige hover:fill-frecWhite"
                width={SOCIAL_SIZE}
                height={SOCIAL_SIZE}
              />
            </Link>
            {/* <Link
                  href="https://www.instagram.com/frecfinance"
                  target="_blank"
                  rel="noreferrer"
                  title="Frec on Instagram"
                >
                  <InstaLogo
                    alt="Instagram"
                    className="fill-frecDarkBeige hover:fill-frecWhite"
                    width={SOCIAL_SIZE}
                    height={SOCIAL_SIZE}
                  />
                </Link> */}
          </div>
          <Link href={FrecWebPath.root} passHref={true} className="my-4">
            <Image
              alt="Frec logo"
              className="p-3 cursor-pointer"
              src="/webstatic/logo/frec-white.svg"
              width={77}
              height={20}
            />
          </Link>
          <div className="text-xs">
            © {currentYear} Frec Markets, Inc. All rights reserved.
          </div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </footer>
  );
};
